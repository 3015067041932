<template>
  <div class="div-main" style="overflow: hidden">
    <div>
      <b-row>
        <b-col xs="12" sm="12" md="12" lg="6" xl="5">
          <div class="div-margin-form">
            <div class="div-logo text-center">
              <img src="https://nixloc.com.br/wp-content/themes/nixloc/shared/images/logo/nixloc.svg" />
            </div>
            <br />
            <Login v-if="!forgotPassword" />
            <ForgotPassword v-else />
            <div v-if="!forgotPassword" @click="forgotPassword = true" class="text-center div-forgot-password">
              Esqueceu a senha?
            </div>
            <div v-else @click="forgotPassword = false" class="text-center div-forgot-password">
              Voltar para o login
            </div>
          </div>
        </b-col>
        <b-col xs="12" sm="12" md="12" lg="6" xl="7">
          <div class="div-mkt">
            <!--<a href="https://linktr.ee/nixloc" target="_blank">-->
            <a href="https://conteudo.nixloc.com.br/clube-de-indicacoes-nixloc/" target="_blank">
              <img class="img-mkt d-none d-md-block"
                src="https://espaco.blob.core.windows.net/nixloc-imagens/nixloc-capa.png" />
            </a>
          </div>
        </b-col>
      </b-row>
      <br />
      <div class="text-center">
        <a href="https://www.instagram.com/nixloc.sistema/?hl=pt-br" target="_blank" class="fa fa-instagram"></a>
        <a href="https://www.facebook.com/nixloc.sistema" target="_blank" class="fa fa-facebook"></a>
        <a href="https://www.youtube.com/channel/UC2uW-eHwBwy_EYXj9ISayWQ" target="_blank" class="fa fa-youtube"></a>
        <a href="https://wa.me/551131362654" target="_blank" class="fa fa-whatsapp"></a>
      </div>
    </div>
  </div>
</template>

<script>
import Molded from "@nixweb/nixloc-ui/src/component/layout/Molded.vue";
import Login from "../../../components/modules/adm/login/Login.vue";
import ForgotPassword from "../../../components/modules/adm/login/ForgotPassword.vue";

export default {
  name: "LoginView",
  components: { Login, Molded, ForgotPassword },
  data() {
    return {
      forgotPassword: false,
    };
  },
};
</script>

<style scoped>
.div-mkt {
  margin-top: 100px;
}

.img-mkt {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin: auto;
}

.div-main {
  margin-top: 3%;
}

.div-logo {
  margin-top: 30px;
  padding: 18px;
}

.div-margin-form {
  margin: auto;
  max-width: 400px;
}

.div-forgot-password {
  margin-top: 15px;
  margin-bottom: 5px;
  cursor: pointer;
  color: #4680a5;
}

.fa {
  padding: 20px;
  font-size: 25px;
  width: 45px;
  text-align: center;
  text-decoration: none;
  color: #c4c4c4;
}

.fa-facebook:hover {
  color: #0b86ee;
}

.fa-youtube:hover {
  color: #bb0000;
}

.fa-instagram:hover {
  color: #125688;
}

.fa-whatsapp:hover {
  color: green;
}
</style>
