export default class Menu {
    constructor() {
        this.itens = [
            {
                order: 0,
                isModule: true,
                rule: "Adm",
                module: "Adm",
                title: "Administração",
                abbreviatedTitle: "Adm",
                isVisible: "all",
                icon: "fa-solid fa-lock",
            },
            {
                isModule: false,
                rule: "Adm",
                module: "Adm",
                type: "group",
                isVisible: "all",
                groupName: "Administração",
                icon: "fas fa-user-lock",
            },
            {
                isModule: false,
                rule: "Adm",
                module: "Adm",
                type: "link",
                title: "Documento",
                //isVisible: "support",
                isVisible: "all",
                routeName: "documentList",
                icon: "fa-solid fa-file-invoice",
                iconColor: "#577696"
            },
            {
                isModule: false,
                rule: "Adm",
                module: "Adm",
                type: "link",
                title: "Empresa",
                isVisible: "all",
                routeName: "company",
                icon: "fas fa-building",
                iconColor: "#577696"
            },
            {
                isModule: false,
                rule: "Adm",
                module: "Adm",
                type: "link",
                title: "Usuário",
                isVisible: "all",
                routeName: "userList",
                icon: "fas fa-users",
                iconColor: "#577696"
            },
            {
                isModule: false,
                rule: "Adm",
                module: "Adm",
                type: "link",
                title: "Loja",
                isVisible: "all",
                routeName: "store",
                icon: "fa-sharp fa-solid fa-bags-shopping",
                iconColor: "#38A169"
            },
            {
                isModule: false,
                rule: "Adm",
                module: "Adm",
                type: "link",
                title: "Parâmetro",
                isVisible: "all",
                routeName: "parameter",
                icon: "fas fa-cogs",
                iconColor: "#577696"
            },
            {
                isModule: false,
                rule: "Adm",
                module: "Adm",
                type: "link",
                title: "Plano",
                isVisible: "all",
                routeName: "plan",
                icon: "fa-regular fa-chart-mixed-up-circle-dollar",
                iconColor: "#577696"
            },
            {
                isModule: false,
                rule: "Adm",
                module: "Adm",
                type: "link",
                title: "Relatório",
                isVisible: "all",
                routeName: "reportAdm",
                icon: "fa-solid fa-file-dashed-line",
                iconColor: "#94AA2A"
            },
            {
                order: 1,
                isModule: true,
                rule: "Signature",
                module: "Signature",
                title: "Assinatura",
                abbreviatedTitle: "Assinan..",
                isVisible: "all",
                icon: "fa-solid fa-signature",
            },
            {
                isModule: false,
                rule: "Signature",
                module: "Signature",
                type: "group",
                isVisible: "all",
                groupName: "Assinatura Digital",
                icon: "fas fa-user-lock",
            },
            {
                isModule: false,
                rule: "Signature",
                module: "Signature",
                type: "link",
                title: "Documento",
                isVisible: "all",
                routeName: "signatureList",
                icon: "fa-solid fa-file-signature",
                iconColor: "#577696"
            },
            {
                isModule: false,
                rule: "Signature",
                module: "Signature",
                type: "link",
                title: "Configuração",
                isVisible: "all",
                routeName: "configSignature",
                icon: "fas fa-cog",
                iconColor: "#528DD7"
            },
            {
                order: 2,
                isModule: true,
                rule: "Billet",
                module: "Billet",
                isVisible: "all",
                title: "Boleto",
                abbreviatedTitle: "Boleto",
                icon: "fa-solid fa-barcode",
            },
            {
                isModule: false,
                rule: "Billet",
                module: "Billet",
                type: "group",
                isVisible: "all",
                groupName: "Boleto Bancário",
                icon: "fas fa-user-lock",
            },
            {
                isModule: false,
                rule: "Billet",
                module: "Billet",
                type: "link",
                title: "Boleto",
                isVisible: "all",
                routeName: "billetList",
                icon: "fa-solid fa-barcode",
                iconColor: "#577696"
            },
            {
                isModule: false,
                rule: "Billet",
                module: "Billet",
                type: "link",
                title: "Remessa e Retorno",
                isVisible: "all",
                routeName: "shipmentReturnList",
                icon: "fa-solid fa-retweet",
                iconColor: "#577696"
            },
            {
                isModule: false,
                rule: "Billet",
                module: "Billet",
                type: "link",
                title: "Integração",
                isVisible: "support",
                routeName: "billetConfigList",
                icon: "fa-solid fa-plug",
                iconColor: "#528DD7"
            },
            {
                isModule: false,
                rule: "Billet",
                module: "Billet",
                type: "link",
                title: "Relatório",
                isVisible: "all",
                routeName: "reportBillet",
                icon: "fa-solid fa-file-dashed-line",
                iconColor: "#94AA2A"
            },
            {
                order: 3,
                isModule: true,
                rule: "CRM",
                module: "CRM",
                isVisible: "all",
                title: "Cliente",
                abbreviatedTitle: "Cliente",
                icon: "fa-sharp fa-solid fa-handshake",
            },
            {
                isModule: false,
                rule: "CRM",
                module: "CRM",
                type: "group",
                isVisible: "all",
                groupName: "Cliente",
                icon: "fas fa-user-lock",
            },
            {
                isModule: false,
                rule: "CRM",
                module: "CRM",
                type: "link",
                title: "Cliente",
                isVisible: "all",
                routeName: "customerList",
                icon: "fas fa-user",
                iconColor: "#577696"
            },
            {
                isModule: false,
                rule: "CRM",
                module: "CRM",
                type: "link",
                title: "Configuração",
                isVisible: "all",
                routeName: "configCrm",
                icon: "fas fa-cog",
                iconColor: "#528DD7"
            },
            {
                isModule: false,
                rule: "CRM",
                module: "CRM",
                type: "link",
                title: "Importar",
                isVisible: "support",
                routeName: "importCustomer",
                icon: "fa-solid fa-up-from-line",
                iconColor: "#D98621"
            },
            {
                isModule: false,
                rule: "CRM",
                module: "CRM",
                type: "link",
                title: "Relatório",
                isVisible: "all",
                routeName: "reportCrm",
                icon: "fa-solid fa-file-dashed-line",
                iconColor: "#94AA2A"
            },
            {
                order: 4,
                isModule: true,
                rule: "Cashier",
                module: "Cashier",
                title: "Caixa",
                isVisible: "all",
                icon: "fa-solid fa-cash-register",
            },
            {
                isModule: false,
                rule: "Cashier",
                module: "Cashier",
                type: "group",
                isVisible: "all",
                groupName: "Caixa",
                icon: "fas fa-user-lock",
            },
            {
                isModule: false,
                rule: "Cashier",
                module: "Cashier",
                type: "link",
                title: "Caixa",
                isVisible: "all",
                routeName: "cashierList",
                icon: "fa-sharp fa-solid fa-cash-register",
                iconColor: "#577696"
            },
            {
                order: 5,
                isModule: true,
                rule: "Stock",
                module: "Stock",
                title: "Estoque",
                abbreviatedTitle: "Estoque",
                isVisible: "all",
                icon: "fa-solid fa-boxes-stacked",
            },
            {
                isModule: false,
                rule: "Stock",
                module: "Stock",
                type: "group",
                isVisible: "all",
                groupName: "Estoque",
                icon: "fas fa-user-lock",
            },
            {
                isModule: false,
                rule: "Stock",
                module: "Stock",
                type: "link",
                title: "Produto",
                isVisible: "all",
                routeName: "productList",
                icon: "fas fa-boxes",
                iconColor: "#577696"
            },
            {
                isModule: false,
                rule: "Stock",
                module: "Stock",
                type: "link",
                title: "Configuração",
                isVisible: "all",
                routeName: "configStock",
                icon: "fas fa-cog",
                iconColor: "#528DD7"
            },
            {
                isModule: false,
                rule: "Stock",
                module: "Stock",
                type: "link",
                title: "Importar",
                isVisible: "support",
                routeName: "importProduct",
                icon: "fa-solid fa-up-from-line",
                iconColor: "#D98621"
            },
            {
                isModule: false,
                rule: "Stock",
                module: "Stock",
                type: "link",
                title: "Relatório",
                isVisible: "all",
                routeName: "reportStock",
                icon: "fa-solid fa-file-dashed-line",
                iconColor: "#94AA2A"
            },
            {
                order: 6,
                isModule: true,
                rule: "Supplier",
                module: "Supplier",
                title: "Fornecedor",
                abbreviatedTitle: "Fornece..",
                icon: "fa-solid fa-truck-field",
            },

            {
                isModule: false,
                rule: "Supplier",
                module: "Supplier",
                type: "group",
                isVisible: "all",
                groupName: "Fornecedor",
                icon: "fas fa-user-lock",
            },
            {
                isModule: false,
                rule: "Supplier",
                module: "Supplier",
                type: "link",
                title: "Fornecedor",
                isVisible: "all",
                routeName: "supplierList",
                icon: "fa-solid fa-users-gear",
                iconColor: "#577696"
            },
            /* {
                 isModule: false,
                 rule: "Supplier",
                 module: "Supplier",
                 type: "link",
                 title: "Pedido de Fornecedor",
                 isVisible: "all",
                 routeName: "supplierOrderList",
                 icon: "fas fa-exchange-alt",
                 iconColor: "#577696"
             },*/
            {
                order: 7,
                isModule: true,
                rule: "Billing",
                module: "Billing",
                title: "Faturamento",
                abbreviatedTitle: "Faturam..",
                icon: "fa-solid fa-file-invoice",
            },
            {
                isModule: false,
                rule: "Billing",
                module: "Billing",
                type: "group",
                isVisible: "all",
                groupName: "Faturamento",
                icon: "fas fa-user-lock",
            },
            {
                isModule: false,
                rule: "Billing",
                module: "Billing",
                type: "link",
                title: "Fatura",
                isVisible: "all",
                routeName: "invoiceList",
                icon: "fa-sharp fa-solid fa-file-invoice",
                iconColor: "#577696"
            },
            {
                isModule: false,
                rule: "Billing",
                module: "Billing",
                type: "link",
                title: "A Faturar",
                isVisible: "all",
                routeName: "toProfitList",
                icon: "fa-solid fa-chart-mixed-up-circle-dollar",
                iconColor: "#577696"
            },
            {
                isModule: false,
                rule: "Billing",
                module: "Billing",
                type: "link",
                title: "Relatório",
                isVisible: "all",
                routeName: "reportBilling",
                icon: "fa-solid fa-file-dashed-line",
                iconColor: "#94AA2A"
            },
            {
                order: 8,
                isModule: true,
                rule: "Finance",
                module: "Finance",
                title: "Financeiro",
                abbreviatedTitle: "Finance..",
                isVisible: "all",
                icon: "fa-solid fa-sack-dollar",
            },
            {
                isModule: false,
                rule: "Finance",
                module: "Finance",
                type: "group",
                isVisible: "all",
                groupName: "Financeiro",
                icon: "fas fa-user-lock",
            },
            /*   {
                   isModule: false,
                   rule: "Finance",
                   module: "Finance",
                   type: "link",
                   title: "Boleto Bancário",
                   routeName: "payableReceivableList",
                   icon: "fa-solid fa-barcode-scan",
                   iconColor: "#577696"
               },*/
            {
                isModule: false,
                rule: "Finance",
                module: "Finance",
                type: "link",
                title: "Pagar e Receber",
                isVisible: "all",
                routeName: "payableReceivableList",
                icon: "fa-solid fa-money-check-dollar-pen",
                iconColor: "#577696"
            },
            {
                isModule: false,
                rule: "Finance",
                module: "Finance",
                type: "link",
                title: "Conciliação",
                isVisible: "all",
                routeName: "bankReconciliation",
                icon: "fa-solid fa-up-from-line",
                iconColor: "#577696"
            },
            {
                isModule: false,
                rule: "Finance",
                module: "Finance",
                type: "link",
                title: "Transferência",
                isVisible: "all",
                routeName: "transferAccountList",
                icon: "fa-solid fa-money-bill-transfer",
                iconColor: "#577696"
            },
            {
                isModule: false,
                rule: "Finance",
                module: "Finance",
                type: "link",
                title: "Configuração",
                isVisible: "all",
                routeName: "configFinance",
                icon: "fas fa-cog",
                iconColor: "#528DD7"
            },
            {
                isModule: false,
                rule: "Finance",
                module: "Finance",
                type: "link",
                title: "Relatório",
                isVisible: "all",
                routeName: "reportFinance",
                icon: "fa-solid fa-file-dashed-line",
                iconColor: "#94AA2A"
            },
            {
                order: 9,
                isModule: true,
                rule: "Maintenance",
                module: "Maintenance",
                title: "Manutenção",
                abbreviatedTitle: "Manuten..",
                isVisible: "all",
                icon: "fa-solid fa-wrench",
            },
            {
                isModule: false,
                rule: "Maintenance",
                module: "Maintenance",
                isVisible: "all",
                type: "group",
                groupName: "Manutenção",
            },
            {
                isModule: false,
                rule: "Maintenance",
                module: "Maintenance",
                type: "link",
                title: "Ordem de Serviço",
                isVisible: "all",
                routeName: "maintenanceList",
                icon: "fa-solid fa-file-lines",
                iconColor: "#577696"
            },
            {
                isModule: false,
                rule: "Maintenance",
                module: "Maintenance",
                type: "link",
                title: "Painel de OS",
                isVisible: "all",
                routeName: "osPanelMaintenanceList",
                icon: "fa-regular fa-solar-panel",
                iconColor: "#577696"
            },
            {
                isModule: false,
                rule: "Maintenance",
                module: "Maintenance",
                type: "link",
                title: "Relatório",
                isVisible: "all",
                routeName: "reportMaintenance",
                icon: "fa-solid fa-file-dashed-line",
                iconColor: "#94AA2A"
            },
            {
                order: 10,
                isModule: true,
                rule: "Rental",
                module: "Rental",
                title: "Locação",
                abbreviatedTitle: "Locação",
                isVisible: "all",
                icon: "fa-sharp fa-solid fa-right-left",
            },
            {
                isModule: false,
                rule: "Rental",
                module: "Rental",
                isVisible: "all",
                type: "group",
                groupName: "Locação",

            },
            {
                isModule: false,
                rule: "Rental",
                module: "Rental",
                type: "link",
                title: "Locação",
                isVisible: "all",
                routeName: "rentalList",
                icon: "fas fa-exchange-alt",
                iconColor: "#577696"
            },
            {
                isModule: false,
                rule: "Rental",
                module: "Rental",
                type: "link",
                title: "Configuração",
                isVisible: "all",
                routeName: "configRental",
                icon: "fas fa-cog",
                iconColor: "#528DD7"
            },
            {
                isModule: false,
                rule: "Rental",
                module: "Rental",
                type: "link",
                title: "Relatório",
                isVisible: "all",
                routeName: "reportRental",
                icon: "fa-solid fa-file-dashed-line",
                iconColor: "#94AA2A"
            },
            {
                order: 11,
                isModule: true,
                rule: "TaxDocument",
                module: "TaxDocument",
                title: "NFs",
                abbreviatedTitle: "NFs",
                isVisible: "all",
                icon: "fa-solid fa-file-invoice-dollar",
            },
            {
                isModule: false,
                rule: "TaxDocument",
                module: "TaxDocument",
                type: "group",
                isVisible: "all",
                groupName: "Nota Fiscal",
                icon: "fas fa-user-lock",
            },
            {
                isModule: false,
                rule: "TaxDocument",
                module: "TaxDocument",
                type: "link",
                title: "NFSe",
                isVisible: "all",
                routeName: "nfseList",
                icon: "fa-solid fa-receipt",
                iconColor: "#577696"
            },
            {
                isModule: false,
                rule: "TaxDocument",
                module: "TaxDocument",
                type: "link",
                title: "NFe",
                isVisible: "all",
                routeName: "nfeList",
                icon: "fa-regular fa-receipt",
                iconColor: "#577696"
            },
            {
                isModule: false,
                rule: "TaxDocument",
                module: "TaxDocument",
                type: "group",
                isVisible: "all",
                groupName: "Recebimento",
                icon: "fas fa-user-lock",
            },
            {
                isModule: false,
                rule: "TaxDocument",
                module: "TaxDocument",
                type: "link",
                title: "Nfe Recebida",
                isVisible: "all",
                routeName: "nfeReceivedList",
                icon: "fa-solid fa-arrows-repeat",
                iconColor: "#577696"
            },
            {
                isModule: false,
                rule: "TaxDocument",
                module: "TaxDocument",
                type: "link",
                title: "Configuração",
                isVisible: "all",
                routeName: "configTaxDocument",
                icon: "fas fa-cog",
                iconColor: "#528DD7"
            },
            {
                order: 12,
                isModule: true,
                rule: "Operational",
                module: "Operational",
                isVisible: "all",
                title: "Operacional",
                abbreviatedTitle: "Operaci..",
                icon: "fa-solid fa-truck-loading",
            },
            {
                isModule: false,
                rule: "Operational",
                module: "Operational",
                type: "group",
                isVisible: "all",
                groupName: "Operacional",
                icon: "fas fa-user-lock",
            },
            {
                isModule: false,
                rule: "Operational",
                module: "Operational",
                type: "link",
                title: "Locação",
                isVisible: "all",
                routeName: "rentOperationalList",
                icon: "fas fa-exchange-alt",
                iconColor: "#577696"
            },
        /*    {
                isModule: false,
                rule: "Operational",
                module: "Operational",
                type: "link",
                title: "Movimentação em Massa",
                isVisible: "all",
                routeName: "movementBulkList",
                icon: "fa-solid fa-cubes-stacked",
                iconColor: "#577696"
            },*/
            {
                isModule: false,
                rule: "Operational",
                module: "Operational",
                type: "link",
                title: "Painel de OS",
                isVisible: "all",
                routeName: "osPanelList",
                icon: "fa-regular fa-solar-panel",
                iconColor: "#577696"
            },
            {
                isModule: false,
                rule: "Operational",
                module: "Operational",
                type: "link",
                title: "Painel de Reserva",
                isVisible: "all",
                routeName: "reservationPanel",
                icon: "fa-solid fa-table-pivot",
                iconColor: "#577696"
            },
            {
                isModule: false,
                rule: "Operational",
                module: "Operational",
                type: "link",
                title: "Pesquisa Retorno",
                isVisible: "all",
                routeName: "operationalSearch",
                icon: "fa-solid fa-magnifying-glass",
                iconColor: "#577696"
            },
            {
                isModule: false,
                rule: "Operational",
                module: "Operational",
                type: "link",
                title: "Relatório",
                isVisible: "all",
                routeName: "reportOperational",
                icon: "fa-solid fa-file-dashed-line",
                iconColor: "#94AA2A"
            },
            {
                order: 13,
                isModule: true,
                rule: "Planner",
                module: "Planner",
                isVisible: "all",
                title: "Planner",
                abbreviatedTitle: "Planner",
                icon: "fa-solid fa-chart-kanban",
            },
            {
                isModule: false,
                rule: "Planner",
                module: "Planner",
                type: "group",
                isVisible: "all",
                groupName: "Planner",
                icon: "fas fa-user-lock",
            },
            {
                isModule: false,
                rule: "Planner",
                module: "Planner",
                type: "link",
                title: "Quadro",
                isVisible: "all",
                routeName: "funnelList",
                icon: "fa-solid fa-clone",
                iconColor: "#577696"
            },
            {
                isModule: false,
                rule: "Planner",
                module: "Planner",
                type: "link",
                title: "Configuração",
                isVisible: "all",
                routeName: "configPlanner",
                icon: "fas fa-cog",
                iconColor: "#528DD7"
            },
            /*    {
                    order: 12,
                    isModule: true,
                    rule: "PDV",
                    module: "PDV",
                    isVisible: "all",
                    title: "PDV",
                    icon: "fa-solid fa-display",
                },
                {
                    isModule: false,
                    rule: "PDV",
                    module: "PDV",
                    type: "group",
                    isVisible: "all",
                    groupName: "PDV",
                    icon: "fas fa-user-lock",
                },
                {
                    isModule: false,
                    rule: "PDV",
                    module: "PDV",
                    type: "link",
                    title: "Locação",
                    isVisible: "all",
                    routeName: "pdvRentSelectOption",
                    icon: "fas fa-exchange-alt",
                    iconColor: "#577696"
                },
                    {
                    isModule: false,
                    rule: "PDV",
                    module: "PDV",
                    type: "group",
                    groupName: "Outros",
                    icon: "fas fa-user-lock",
                },
                {
                    isModule: false,
                    rule: "PDV",
                    module: "PDV",
                    type: "link",
                    title: "Relatório",
                    routeName: "reportOperational",
                    icon: "fa-solid fa-file-dashed-line",
                    iconColor: "#94AA2A"
                },*/
            {
                order: 14,
                isModule: true,
                rule: "HumanResources",
                module: "HumanResources",
                isVisible: "all",
                title: "RH",
                abbreviatedTitle: "RH",
                icon: "fa-solid fa-user-chart",
            },
            {
                isModule: false,
                rule: "HumanResources",
                module: "HumanResources",
                type: "group",
                isVisible: "all",
                groupName: "Recursos Humanos",
                icon: "fas fa-user-lock",
            },
            {
                isModule: false,
                rule: "HumanResources",
                module: "HumanResources",
                type: "link",
                title: "Colaborador",
                isVisible: "all",
                routeName: "collaboratorList",
                icon: "fas fa-users-class",
                iconColor: "#577696"
            },
        ]
    }
}
